import SignOutButton from "modules/auth/components/SignOutButton";
import UserAvatarMenu from "modules/auth/components/UserAvatarMenu";
import useBusinessGuid from "modules/jwt/queries/useBusinessGuid";
import useUserGuid from "modules/jwt/queries/useUserGuid";
import { FC, ReactNode } from "react";
import Page, { PageHeaderProps } from "ui/data-display/Page";
import BarChart from "ui/data-visualization/BarChart";
import HighbeamLogo from "ui/icons/HighbeamLogo";
import cn from "utils/tailwind/cn";

const OnboardingLayoutHeader: FC<Omit<PageHeaderProps, "center">> = (props) => {
  // TODO(alex) HB-5859: This check doesn't appear necessary by looking at ts types but actually those types are wrong, so we need to do this check or else the user avatar menu will crash.
  const businessGuid = useBusinessGuid({ loginRequired: false });
  const userGuid = useUserGuid({ loginRequired: false });

  return (
    <Page.Header
      center={<HighbeamLogo withText className="h-8 px-8" />}
      right={
        <>
          {businessGuid && (
            <UserAvatarMenu
              // Aligns the dropdown user avatar with the user avatar button.
              dropdownClassName="mt-2 pt-[0.5625rem] tablet:mt-0 tablet:pt-0"
            />
          )}
          {!businessGuid && userGuid && <SignOutButton />}
        </>
      }
      {...props}
    />
  );
};

type Props = {
  progressBarPercentage?: number;
  children: ReactNode;
  header?: ReactNode;
  showBackgroundImage?: boolean;
  className?: string;
};

const OnboardingLayout: FC<Props> = ({
  children,
  header = <OnboardingLayoutHeader />,
  progressBarPercentage,
  showBackgroundImage = true,
  className,
}) => {
  return (
    <>
      {progressBarPercentage !== undefined && (
        <BarChart className="fixed z-10 w-full" height={8} roundedCornerVariant="rounded-none">
          <BarChart.Bar
            color="purple"
            widthPercentage={progressBarPercentage}
            className="rounded-l-none"
          />
        </BarChart>
      )}

      <Page
        className={cn("bg-grey-50", showBackgroundImage && "auth-0-sm:bg-highbeam-logo", className)}
      >
        {header}

        <Page.BodyCenter>{children}</Page.BodyCenter>
      </Page>
    </>
  );
};

export default Object.assign(OnboardingLayout, {
  Header: OnboardingLayoutHeader,
});
