import OnboardingLayout from "layouts/OnboardingLayout";
import { useHasFilledOutBankApplication } from "modules/bank-application/queries/useBankApplicationQueryOptions";
import OnboardingConnectStoresCard from "modules/onboarding/components/OnboardingConnectStoresCard";
import { Navigate } from "react-router-dom";

const OnboardingConnectStoresPage = () => {
  const hasFilledOutBankApplication = useHasFilledOutBankApplication();

  // Let `OnboardingNavigate` determine where the user should go next if they haven't filled out their bank application yet.
  if (!hasFilledOutBankApplication) {
    return <Navigate to="/onboarding" />;
  }

  return (
    <OnboardingLayout progressBarPercentage={90}>
      <OnboardingConnectStoresCard />
    </OnboardingLayout>
  );
};

export default OnboardingConnectStoresPage;
