import { BANK_ACCOUNT_TYPE } from "modules/accounting-accounts/utils/config";
import { FC, ComponentPropsWithoutRef, useCallback } from "react";
import AccountingAccountRep from "reps/AccountingAccountRep";
import BankAccountRep from "reps/BankAccountRep";

import AccountingAccountBar from "../AccountingAccountBar";
import AccountingCategorySelect, { ItemRenderProps } from "../AccountingCategorySelect";

type Props = Omit<
  ComponentPropsWithoutRef<typeof AccountingCategorySelect>,
  "filter" | "renderItem" | "clearable"
> & {
  bankAccount: BankAccountRep.Complete;
};

const BankAccountChartOfAccountSelect: FC<Props> = ({ bankAccount, ...props }) => {
  const filter = useCallback(
    (accountingAccount: AccountingAccountRep.Complete) =>
      accountingAccount.accountType === BANK_ACCOUNT_TYPE,
    []
  );

  const renderItem = useCallback(
    ({ accountingAccount }: ItemRenderProps) => {
      const { id, name, bankAccountGuid, status, nominalCode } = accountingAccount;
      const isActive = status === "active";
      const isAssociatedWithDifferentBankAccount =
        bankAccountGuid !== null && bankAccountGuid !== bankAccount.guid && isActive;

      return (
        <AccountingCategorySelect.Item
          key={id}
          value={id}
          searchableText={name}
          disabled={isAssociatedWithDifferentBankAccount || !isActive}
        >
          <AccountingAccountBar accountingAccount={accountingAccount}>
            {nominalCode && <AccountingAccountBar.NominalCode nominalCode={nominalCode} />}
            <span>
              {name}
              {isAssociatedWithDifferentBankAccount && " (associated with another account)"}
            </span>
          </AccountingAccountBar>
        </AccountingCategorySelect.Item>
      );
    },
    [bankAccount]
  );

  return <AccountingCategorySelect filter={filter} renderItem={renderItem} clearable {...props} />;
};

export default BankAccountChartOfAccountSelect;
