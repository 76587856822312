import { useSuspenseQuery } from "@tanstack/react-query";
import { useHasFilledOutBankApplication } from "modules/bank-application/queries/useBankApplicationQueryOptions";
import { useCurrentBusinessMember } from "modules/business-members/queries/businessMemberQueryHooks";
import useBusiness from "modules/business/queries/useBusiness";
import useRutterConnectionsQueryOptions from "modules/connected-stores/queries/useRutterConnectionsQueryOptions";
import useShopifyConnectionsQueryOptions from "modules/connected-stores/queries/useShopifyConnectionsQueryOptions";
import useIsQualifiedForOnboardingConnectPlaid from "modules/onboarding/hooks/useIsQualifiedForOnboardingConnectPlaid";
import { usePlaidConnections } from "modules/plaid/queries/plaidConnectionsQueryHooks";
import { useMemo } from "react";
import { Navigate } from "react-router-dom";
import useIsAllowedToConnectStores from "utils/permissions/useIsAllowedToConnectStores";

const useHasShopifyOrAmazonConnection = () => {
  const { data: amazonConnections } = useSuspenseQuery({
    ...useRutterConnectionsQueryOptions(),
    select: (rutterConnections) =>
      rutterConnections.filter((rutterConnection) => rutterConnection.platformName === "AMAZON"),
  });

  const { data: shopifyConnections } = useSuspenseQuery(useShopifyConnectionsQueryOptions());

  return amazonConnections.length + shopifyConnections.length > 0;
};

const useDetermineOnboardingStepPath = () => {
  const hasFilledOutBankApplication = useHasFilledOutBankApplication();

  const plaidConnections = usePlaidConnections();
  const hasPlaidConnections = plaidConnections.length > 0;
  const isQualifiedForOnboardingConnectPlaid = useIsQualifiedForOnboardingConnectPlaid();

  const businessMember = useCurrentBusinessMember();
  const business = useBusiness();

  const hasShopifyOrAmazonConnection = useHasShopifyOrAmazonConnection();
  const isAllowedToConnectStores = useIsAllowedToConnectStores();

  return useMemo(() => {
    const hasDateOfBirth = Boolean(businessMember?.dateOfBirth);
    const isBusinessOwner = businessMember && business.ownerUserGuid === businessMember.userGuid;

    if (!hasPlaidConnections && isQualifiedForOnboardingConnectPlaid) {
      return "/onboarding/connect-accounts-and-cards";
    }

    if (hasFilledOutBankApplication && !hasDateOfBirth && !isBusinessOwner) {
      return "/onboarding/personal-info";
    }

    if (
      business.status !== "Active" &&
      isAllowedToConnectStores &&
      hasFilledOutBankApplication &&
      !hasShopifyOrAmazonConnection
    ) {
      return "/onboarding/connect-stores";
    }

    if (hasFilledOutBankApplication) {
      return "/onboarding/get-started";
    } else {
      return "/onboarding/bank-application";
    }
  }, [
    hasFilledOutBankApplication,
    hasShopifyOrAmazonConnection,
    businessMember,
    business,
    isAllowedToConnectStores,
    hasPlaidConnections,
    isQualifiedForOnboardingConnectPlaid,
  ]);
};

const OnboardingNavigate = () => {
  const to = useDetermineOnboardingStepPath();
  return <Navigate replace to={to} />;
};

export default OnboardingNavigate;
