import chatDebugModeState from "modules/chat/state/chatDebugMode";
import React from "react";
import { useRecoilValue } from "recoil";
import { ExchangeRep } from "reps/chat/ExchangeRep";

import ExchangeLoading from "../ExchangeLoading";

import ExceptionMessage from "./ExceptionMessage";
import ExchangeProfileSnippet from "./ExchangeProfileSnippet";
import ResponseMessage from "./ResponseMessage";
import UserMessage from "./UserMessage";

type Props = {
  exchange: ExchangeRep;
  isLast: boolean;
};

const Exchange: React.FC<Props> = ({ exchange, isLast }) => {
  const chatDebugMode = useRecoilValue(chatDebugModeState);

  return (
    <div className="flex flex-col gap-6 border-t border-grey-100 pb-10 pt-8 first-of-type:border-t-0">
      <UserMessage message={exchange.userMessage} />
      {chatDebugMode && <ExchangeProfileSnippet profile={exchange.profile ?? undefined} />}
      {exchange.responseMessage && <ResponseMessage message={exchange.responseMessage} />}
      {exchange.exceptionMessage && <ExceptionMessage message={exchange.exceptionMessage} />}
      {isLast && exchange.state === "Initial" && (
        <ExchangeLoading messages={exchange.loadingMessages} />
      )}
    </div>
  );
};

export default Exchange;
