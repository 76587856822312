import { FC } from "react";
import { Heading1, Paragraph } from "ui/typography";

import OnboardingCard from "./OnboardingCard";

type Props = {
  className?: string;
};

const OnboardingErrorCard: FC<Props> = ({ className }) => {
  return (
    <OnboardingCard className={className}>
      <OnboardingCard.Header className="pt-8">
        <Heading1 className="text-xl font-bold">Something went wrong</Heading1>
        <Paragraph className="mt-1 text-sm text-grey-500">
          We are sorry, there is a temporary issue with your signup. Please come back in a few
          minutes, or reach out to support.
        </Paragraph>
      </OnboardingCard.Header>
    </OnboardingCard>
  );
};

export default OnboardingErrorCard;
