import { FC } from "react";
import BankApplicationRep from "reps/BankApplicationRep";
import ButtonLink from "ui/inputs/ButtonLink";
import { Heading1, Paragraph } from "ui/typography";
import variants from "utils/ts/variants";

import OnboardingCard from "./OnboardingCard";

export type OnboardingBankApplicationPendingStatus =
  | BankApplicationRep.Status.Pending
  | BankApplicationRep.Status.PendingReview
  | BankApplicationRep.Status.AwaitingDocuments;

type Props = {
  bankApplicationStatus: OnboardingBankApplicationPendingStatus;
  bankApplicationUrl: string;
  className?: string;
};

const OnboardingBankApplicationPendingCard: FC<Props> = ({
  bankApplicationStatus,
  bankApplicationUrl,
  className,
}) => {
  const title = variants(bankApplicationStatus, {
    [BankApplicationRep.Status.Pending]: "Almost done!",
    [BankApplicationRep.Status.PendingReview]: "Almost done!",
    [BankApplicationRep.Status.AwaitingDocuments]: "Additional documents required",
  });

  const paragraph = variants(bankApplicationStatus, {
    [BankApplicationRep.Status.Pending]:
      "We are processing your information and we will contact you once your Highbeam account is ready to use.",
    [BankApplicationRep.Status.PendingReview]:
      "We are processing your information and we will contact you once your Highbeam account is ready to use.",
    [BankApplicationRep.Status.AwaitingDocuments]:
      "Please upload the additional documentation to complete your application.",
  });

  return (
    <OnboardingCard className={className}>
      <OnboardingCard.Header>
        <Heading1 className="mb-2 font-bold">{title}</Heading1>
        <Paragraph className="text-sm text-grey-500">{paragraph}</Paragraph>
      </OnboardingCard.Header>

      {bankApplicationStatus === BankApplicationRep.Status.AwaitingDocuments ? (
        <OnboardingCard.Footer className="mt-8 flex justify-end gap-x-4 border-t border-t-grey-200 px-8 py-5">
          <ButtonLink variant="primary" to={bankApplicationUrl}>
            Upload documents
          </ButtonLink>
        </OnboardingCard.Footer>
      ) : (
        <OnboardingCard.Body />
      )}
    </OnboardingCard>
  );
};

export default OnboardingBankApplicationPendingCard;
