import OnboardingLayout from "layouts/OnboardingLayout";
import useBusiness from "modules/business/queries/useBusiness";
import OnboardingGetStartedContent from "modules/onboarding/components/OnboardingGetStartedContent";
import useGetStartedMutation from "modules/onboarding/mutations/useGetStartedMutation";
import ApplicationEvaluationPage from "pages/ApplicationEvaluationPage";

const OnboardingGetStartedPage = () => {
  const { mutate: getStarted, isPending } = useGetStartedMutation({
    onSuccess: () => {
      window.location.href = "/"; // Intended to force a refresh.
    },
  });

  const business = useBusiness();
  const isBusinessActive = business.status === "Active";

  if (!isBusinessActive) {
    return <ApplicationEvaluationPage />;
  }

  return (
    <OnboardingLayout className="bg-purple-50" showBackgroundImage={false}>
      <OnboardingGetStartedContent onGetStarted={() => getStarted()} isLoading={isPending} />
    </OnboardingLayout>
  );
};

export default OnboardingGetStartedPage;
