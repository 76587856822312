import { ChatTeardropDots } from "@phosphor-icons/react";
import logo from "assets/highbeam-logo.svg";
import { useChatWidget } from "components/ChatWidget";
import SignOutButton from "modules/auth/components/SignOutButton";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "./RejectedBankingApplicationPage.module.scss";

const RejectedBankingApplicationPage = () => {
  const chat = useChatWidget();

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <img src={logo} className={styles.logo} alt="logo" />
        <SignOutButton />
      </div>
      <div className={styles.content}>
        <Text size={20} weight="bold" color={colors.grey[900]}>
          Your banking application was not approved
        </Text>
        <Text size={14} color={colors.grey[600]}>
          Unfortunately, we cannot approve your Highbeam banking application. If you think there was
          a mistake or have questions, reach out Highbeam support.
        </Text>
        <div className={styles.contactSupport} onClick={() => chat.show()}>
          <ChatTeardropDots size={16} color={colors.purple[500]} />
          <Text size={12} weight="medium" color={colors.purple[500]}>
            Contact support
          </Text>
        </div>
      </div>
    </div>
  );
};

export default RejectedBankingApplicationPage;
