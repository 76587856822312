import { FC, ReactElement, ComponentPropsWithoutRef, useMemo } from "react";
import AccountingAccountRep from "reps/AccountingAccountRep";
import Combobox from "ui/inputs/Combobox";
import { toTitleCase } from "utils/string";
import cn from "utils/tailwind/cn";

import { useAccountingAccountsGroups } from "../../queries/useAccountingAccounts";
import makeAccountingAccountDisplayName from "../../utils/makeAccountingAccountDisplayName";
import AccountingAccountBar from "../AccountingAccountBar";
import ContactSupportToConnectAccounting from "../ContactSupportToConnectAccounting";

const AccountingCategorySelectItem = Combobox.Item;

export type ItemRenderProps = {
  accountingAccount: AccountingAccountRep.Complete;
  depth: number;
};

type Props = ComponentPropsWithoutRef<typeof Combobox> & {
  labelText?: string;
  noAccountsDisplayVariant?: "contact-support" | "not-available";
  filter?: (accountingAccount: AccountingAccountRep.Complete) => boolean;
  renderItem?: (props: ItemRenderProps) => ReactElement<typeof AccountingCategorySelectItem>;
};

const AccountingCategorySelect: FC<Props> = ({
  value,
  onValueChange,
  labelText = "Accounting category",
  placeholder,
  clearable = false,
  disabled = false,
  noAccountsDisplayVariant = "contact-support",
  variant = "default",
  filter,
  renderItem,
  ...props
}) => {
  const accountingAccountsGroups = useAccountingAccountsGroups(filter);
  const accountingAccountsGroupsCount = accountingAccountsGroups.length;
  const hasAccountingAccounts = accountingAccountsGroupsCount > 0;

  const accountingAccountsLookup = useMemo(() => {
    const lookup = new Map<string, AccountingAccountRep.Complete>();
    accountingAccountsGroups.forEach(({ accountingAccountsWithDepth }) => {
      accountingAccountsWithDepth.forEach(([accountingAccount]) => {
        lookup.set(accountingAccount.id, accountingAccount);
      });
    });
    return lookup;
  }, [accountingAccountsGroups]);

  return (
    <Combobox
      clearable={clearable}
      disabled={disabled || !hasAccountingAccounts}
      value={value}
      onValueChange={onValueChange}
      placeholder={placeholder}
      variant={variant}
      {...props}
    >
      <Combobox.Field>
        {(hasAccountingAccounts || noAccountsDisplayVariant === "contact-support") && (
          <Combobox.Label>{labelText}</Combobox.Label>
        )}
        {!hasAccountingAccounts && noAccountsDisplayVariant === "not-available" ? (
          <Combobox.Trigger aria-label={labelText}>Not available</Combobox.Trigger>
        ) : (
          <Combobox.Trigger>
            {value && (
              <AccountingAccountBar accountingAccount={accountingAccountsLookup.get(value)!} />
            )}
            {!value && placeholder && <span className="text-grey-500">{placeholder}</span>}
          </Combobox.Trigger>
        )}

        {!hasAccountingAccounts && noAccountsDisplayVariant === "contact-support" && (
          <ContactSupportToConnectAccounting
            className={cn("absolute right-4 top-2.5", variant === "minimal" && "top-1")}
          />
        )}
      </Combobox.Field>
      <Combobox.Menu>
        <Combobox.MenuEmptyState>No accounting categories found.</Combobox.MenuEmptyState>

        {accountingAccountsGroups.map(({ category, accountingAccountsWithDepth }) => (
          <Combobox.Group
            key={category}
            heading={
              /* Only render heading if more than 1 group */ accountingAccountsGroupsCount > 1
                ? toTitleCase(category)
                : undefined
            }
          >
            {accountingAccountsWithDepth.map(([accountingAccount, depth]) => {
              if (renderItem) {
                return renderItem({ accountingAccount, depth });
              }

              const { id, name, nominalCode, status } = accountingAccount;
              const isActive = status === "active";

              // Note that it's common for multiple accounting accounts to have the same name.
              // For example, an accounting account named "Foo" might exist in both "Expense" and
              // "Income" categories (as two discrete accounting accounts). Cmdk will auto-select
              // an item based on its value if the current search text matches the value (i.e. its
              // searchableText), which can cause it to auto-select multiple items if they have
              // the same value. We avoid this by including both the name and category in the
              // searchableText.

              const searchableText = nominalCode
                ? `${makeAccountingAccountDisplayName(accountingAccount)} (${category}) (${nominalCode})`
                : `${name} (${category})`;

              return (
                <AccountingCategorySelectItem
                  key={id}
                  value={id}
                  searchableText={searchableText}
                  keywords={[category]}
                  disabled={!isActive}
                >
                  <AccountingAccountBar accountingAccount={accountingAccount} depth={depth} />
                </AccountingCategorySelectItem>
              );
            })}
          </Combobox.Group>
        ))}
      </Combobox.Menu>
    </Combobox>
  );
};

export default Object.assign(AccountingCategorySelect, {
  Item: AccountingCategorySelectItem,
});
