import { FC } from "react";
import Card from "ui/data-display/Card";
import { PropsWithChildrenAndClassName } from "utils/react-helpers/types";
import cn from "utils/tailwind/cn";

const OnboardingCard: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return <Card className={cn("mb-32 w-full max-w-2xl", className)}>{children}</Card>;
};

const OnboardingCardHeader: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return <div className={cn("px-8 pt-8", className)}>{children}</div>;
};

const OnboardingCardBody: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return <div className={cn("px-8 py-8", className)}>{children}</div>;
};

const OnboardingCardFooter: FC<PropsWithChildrenAndClassName> = ({ children, className }) => {
  return (
    <div className={cn("flex justify-end gap-x-4 border-t border-t-grey-200 px-8 py-5", className)}>
      {children}
    </div>
  );
};

export default Object.assign(OnboardingCard, {
  Header: OnboardingCardHeader,
  Body: OnboardingCardBody,
  Footer: OnboardingCardFooter,
});
