import clock from "assets/clock.svg";
import useArchiveBusinessMutation from "modules/businesses/mutations/useArchiveBusinessMutation";
import businessesByMemberUserQueryHooks from "modules/businesses/queries/businessesByMemberUserQueryHooks";
import useConfirm from "modules/confirmation-modal/useConfirm";
import useUserGuid from "modules/jwt/queries/useUserGuid";
import { useHasPlaidConnections } from "modules/plaid/queries/plaidConnectionsQueryHooks";
import { FC } from "react";
import { useResetRecoilState } from "recoil";
import BankApplicationRep from "reps/BankApplicationRep";
import businessGuidState from "state/auth/businessGuid";
import AccentCard from "ui/data-display/AccentCard";
import Pill from "ui/data-display/Pill";
import Button from "ui/inputs/Button";
import ButtonLink from "ui/inputs/ButtonLink";
import BackButtonLink from "ui/navigation/BackButton";
import IconWithTooltip from "ui/overlay/IconWithTooltip";
import { Heading1, Heading4, Paragraph } from "ui/typography";

import useIsQualifiedForOnboardingConnectPlaid from "../hooks/useIsQualifiedForOnboardingConnectPlaid";

import OnboardingCard from "./OnboardingCard";

type Props = {
  bankApplication: BankApplicationRep.Complete;
  className?: string;
};

const OnboardingStartBankApplicationCard: FC<Props> = ({ bankApplication, className }) => {
  const resetBusinessGuid = useResetRecoilState(businessGuidState);
  const userGuid = useUserGuid();
  const hasAtLeastOneActiveBusiness = businessesByMemberUserQueryHooks.useData({
    userGuid,
    select: (businesses) => businesses.some((business) => business.status === "Active"),
  });

  const isQualifiedForOnboardingConnectPlaid = useIsQualifiedForOnboardingConnectPlaid();
  const hasPlaidConnections = useHasPlaidConnections();

  const { mutateAsync: archiveBusinessApplication, isPending: isArchivingBusinessApplication } =
    useArchiveBusinessMutation({
      onSuccess: () => {
        resetBusinessGuid();
        window.location.href = "/"; // Intended to force a refresh.
      },
    });

  const confirm = useConfirm();

  return (
    <OnboardingCard className={className}>
      <OnboardingCard.Header>
        <Heading1 className="text-xl font-bold">Verify your business details</Heading1>
        <Paragraph className="mt-1 text-sm text-grey-500">
          Before getting started with Highbeam banking or capital, please complete a quick business
          verification.
        </Paragraph>
      </OnboardingCard.Header>
      <OnboardingCard.Body>
        <AccentCard withTopBorder>
          <div className="flex justify-between">
            <Heading4>You’ll need</Heading4>
            <Pill
              color="purple-100"
              iconLeft={({ sizeClassName }) => <img src={clock} alt="" className={sizeClassName} />}
            >
              3-5 minutes
            </Pill>
          </div>

          <ul className="my-3 list-inside list-disc text-xs leading-relaxed text-grey-900">
            <li className="ml-0.5">Your social security number (SSN)</li>
            <li className="ml-0.5 mt-3">
              Employer identification number (EIN)
              <IconWithTooltip
                tooltip="You can find this number on your tax documents or W2, 1099 forms."
                className="-mb-1 ml-1"
              />
            </li>
          </ul>

          <div className="mt-6">
            <Paragraph className="text-xs text-grey-600">
              We require this information to ensure security and compliance with federal
              regulations. We never share this information.
            </Paragraph>
          </div>
        </AccentCard>
      </OnboardingCard.Body>

      <OnboardingCard.Footer>
        {isQualifiedForOnboardingConnectPlaid && (
          <BackButtonLink
            to={
              hasPlaidConnections
                ? "/onboarding/book-a-time"
                : "/onboarding/connect-accounts-and-cards"
            }
            className="mr-auto"
          />
        )}
        {hasAtLeastOneActiveBusiness && (
          <Button
            variant="default"
            isLoading={isArchivingBusinessApplication}
            onClick={async () => {
              await confirm({
                header: "Cancel banking application?",
                message: "If you cancel your banking application, you will lose your progress.",
                buttonLabel: "Cancel application",
                variant: "danger",
                onConfirm: async () => {
                  await archiveBusinessApplication({
                    businessGuid: bankApplication.businessGuid,
                  });
                },
              });
            }}
          >
            Cancel application
          </Button>
        )}

        <ButtonLink to={bankApplication.url!} variant="primary">
          Start verification
        </ButtonLink>
      </OnboardingCard.Footer>
    </OnboardingCard>
  );
};

export default OnboardingStartBankApplicationCard;
