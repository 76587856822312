import { useSuspenseQuery } from "@tanstack/react-query";
import OnboardingLayout from "layouts/OnboardingLayout";
import useCreateBankApplicationMutation from "modules/bank-application/mutations/useCreateBankApplicationMutation";
import useBankApplicationQueryOptions from "modules/bank-application/queries/useBankApplicationQueryOptions";
import LoadingPage from "modules/loading/pages/LoadingPage";
import OnboardingBankApplicationPendingCard from "modules/onboarding/components/OnboardingBankApplicationPendingCard";
import OnboardingStartBankApplicationCard from "modules/onboarding/components/OnboardingStartBankApplicationCard";
import { FC } from "react";
import { Navigate } from "react-router-dom";
import BankApplicationRep from "reps/BankApplicationRep";
import useMountEffect from "utils/customHooks/useMountEffect";

const OnboardingBankApplicationPage: FC = () => {
  const { data: bankApplication } = useSuspenseQuery(useBankApplicationQueryOptions());

  const { mutate: createBankApplication } = useCreateBankApplicationMutation();

  useMountEffect(() => {
    if (!bankApplication) {
      createBankApplication();
    }
  });

  // Show the loading page while the bank application is being created.
  if (!bankApplication) {
    return <LoadingPage location="NoBankApplication" />;
  }

  switch (bankApplication.status) {
    case BankApplicationRep.Status.FormCreated:
      return (
        <OnboardingLayout progressBarPercentage={30}>
          <OnboardingStartBankApplicationCard bankApplication={bankApplication} />
        </OnboardingLayout>
      );
    case BankApplicationRep.Status.Approved:
    case BankApplicationRep.Status.Denied:
      return <Navigate replace to="/onboarding/get-started" />;
    case BankApplicationRep.Status.Pending:
    case BankApplicationRep.Status.PendingReview:
    case BankApplicationRep.Status.AwaitingDocuments:
      return (
        <OnboardingLayout>
          <OnboardingBankApplicationPendingCard
            bankApplicationStatus={bankApplication.status}
            bankApplicationUrl={bankApplication.url!}
          />
        </OnboardingLayout>
      );
  }
};

export default OnboardingBankApplicationPage;
